import React,{useEffect} from 'react'
import { NavLink ,Link} from 'react-router-dom'
import {  Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getFirebase } from 'react-redux-firebase';
//import {loginShow,Userlogout} from '../store/actions/authActions'

import { useLocation   } from 'react-router-dom'

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
//import MenuIcon from '@mui/icons-material/Menu'; 
//import MemoryIcon from '@mui/icons-material/Memory';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import { withFirestore, isLoaded, isEmpty } from 'react-redux-firebase'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';



const Header = ({user}) => {


    return (<header className="Header">
               <Box className="HeaderMenu"> 
                <NavLink className="headerMenuItem" to="/" >Home</NavLink>
                <Box className="HeaderSpacer">|</Box>
                <NavLink className="headerMenuItem"  to="/questions">Questions</NavLink>
                <Box className="HeaderSpacer">|</Box>
                <NavLink className="headerMenuItem"  to="/shop">Support us</NavLink>
                <Box className="HeaderSpacer">|</Box>
                <NavLink className="headerMenuItem"  to="/profile" ><PersonIcon/></NavLink>
                    
</Box>   
            </header>);
}


const mapStateToProps = (state, ownProps) => {
    return {
        user:firebase.auth().currentUser,
    }
  }
  
  
  
  export default compose(
    connect(mapStateToProps),
  )(Header);
  