import React, {useState  } from 'react'
//import firebase from 'firebase';
import {Container,TextField,DialogActions,DialogContentText,DialogContent,DialogTitle,Dialog,List,ListItemButton,Button,ListItemText} from '@mui/material';
import Alert from '@mui/material/Alert';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { compose } from 'redux'
import { isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

//import {SubmitContact} from '../store/actions/ContactAction'
import { connect } from 'react-redux'
import QRCode from "react-qr-code";

import axios from 'axios'
import Login from "../Auth/Login"

import Sidebar from "../../components/profile/Sidebar"
import Loader from "../../components/other/Loader"
import Header from "../../components/header/Header"
import { Box } from '@mui/system';

var timeoutTimer = null


function View({user}) { 

  const [Selected, setSelected] = useState();
  const [Tag, setTag] = useState();
  const [values, setValues] = useState();
  const [{loading,error}, setNewTag] = useState({});
  const [limit, setLimit] = useState(30);
  const [{loadingTags}, setForm] = useState({loadingTags:true});
  const [openAddDialog, setAddDialog] = useState(false);

  const auth = useSelector(state => state.firebase.auth)


  
  const handleOpenAddDialog= () => {
    setAddDialog(true);
    console.log("open")
  };

  const handleCloseAddDialog = () => {
    setAddDialog(false);
    setNewTag({})
    setSelected()
  };


  const handleTimeout = () => {
    if(loadingTags)
      setForm({ error:"Timeout"});
  };
  
  const LoadMore = ()=>{
    LoadTags(limit+30)
    setLimit(limit+30)
  }

  const LoadTags = (LimitSize=null) =>{
    
    timeoutTimer = setTimeout(handleTimeout, 30000)
    firebase.auth().currentUser.getIdToken().then(function(idToken) {

    axios.post('https://api.uniqule.com/profile/tags',{limit:LimitSize?LimitSize:limit},{"headers":{"Authorization": idToken}}).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);

      console.log(Result.data)

      if(Result.data && Result.data.tags)
      {
        setValues(Result.data.tags);
        setForm({});
      }
    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setForm({ error:"Server error"});
    });
  })
  }


  
  const SubmitTag = () =>{
    
    setNewTag({loading:true})
    timeoutTimer = setTimeout(handleTimeout, 30000)


    firebase.auth().currentUser.getIdToken().then(function(idToken) {

    axios.post('https://api.uniqule.com/tag/add',{name:Tag},{"headers":{"Authorization": idToken}}).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);

      console.log(Result.data)

      if(Result.data && Result.data.id)
      {
        setNewTag({save:true})
        setAddDialog(false);
        setSelected(Result.data.id)
        setTag()
        LoadTags()
      }
      else
      {
        setNewTag({error:"Server error"})
      }
    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setNewTag({error:"Cannot submit"})
    });
  })
  }


  const handleQRPrint=()=>{
    window.open("/barcode.html?id="+Selected,'PrintBarcode','width=600,height=800'); 
    return false;
  }



  if(!isLoaded(auth))
  {
    return <main ><Header/><Loader/></main >
  }
  
  if(!user)
  {
    return <Login/>
  }


  if(loadingTags )
  {
    LoadTags()
    return <main ><Header/><Container><Sidebar><Loader/></Sidebar></Container ></main >
  }



  return(<main >
          <Header/>
            <Container >
              <Sidebar>

              <Box className="ProfileHeader">
                <Box className="ProfileHeaderContent">
                  <Button onClick={handleOpenAddDialog}>Add</Button>
                </Box>
              </Box>


                <List  className="TagList">
                {values && values.length === 0 ? <ListItemText className="center" primary="You have No tags, please add one."/>: null}


                    {values && values.map((item)=>{
                        return (<ListItemButton className="TagListItem" onClick={()=>setSelected(item.id)}>
                                  <ListItemText className="tag" primary={<span>{item.name ? <><strong>{item.name}</strong> - </>: null}  {item.id}</span>}  secondary={<span>Answered: {item.countAnswered ? item.countAnswered : 0}</span>}/>
                                </ListItemButton>
                        )
                    })}



{values && values.length > 0 ? <ListItemButton onClick={LoadMore}  className="loadMoreButton " >
                        <ListItemText id="Load More" primary="Load More" />
                      </ListItemButton>:null}
                </List >


              </Sidebar>
            </Container>






            <Dialog
              open={Selected != null}
              //onClose={handleCloseAddDialog}
              //PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
      
            >
              <DialogTitle>
                Tag Information
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                <Box>
                  <Box>
                    <QRCode value={"https://uniqule.com/id/"+Selected} />
                  </Box>
                  
                  <Box className="center">
                    <Button 
                      /*target="PrintBarcode" */
                      /*href={"/barcode.html?id="+Selected}*/
                      onClick={handleQRPrint}
                    >
                      Print
                    </Button>
                  </Box>

                  <Box >
                  <TextField  value={"https://uniqule.com/id/"+Selected} onClick={()=>navigator.clipboard.writeText("https://uniqule.com/id/"+Selected)} disabled fullWidth  variant="outlined" />
                  </Box>

                  <Box  className="center">
                  Click to copy
                  </Box>

                </Box>

                </DialogContentText>
              </DialogContent>
              <DialogActions>
               <Button autoFocus onClick={handleCloseAddDialog}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>





<Dialog
  open={openAddDialog}
  //onClose={handleCloseAddDialog}
  //PaperComponent={PaperComponent}
  aria-labelledby="draggable-dialog-title"
  maxWidth="md"
  fullWidth

>
  <DialogTitle>
    Add Tag
  </DialogTitle>
  <DialogContent>
    <DialogContentText >
      <Box >
        {error ? <Alert severity="error">Error — {error}</Alert> :null}
        {loading ? <Alert severity="info">Loading  —  please wait.</Alert> :null}
      </Box>

      <TextField margin="dense" value={Tag} onChange={(event)=>setTag(event.target.value)} multiline fullWidth label="Tag Name" variant="outlined" />

    </DialogContentText>



  </DialogContent>
  <DialogActions>
   <Button autoFocus onClick={handleCloseAddDialog}>
      Cancel
    </Button>
    <Button onClick={SubmitTag}>Submit</Button>
  </DialogActions>
</Dialog>

            
          </main>
  );
}




const mapStateToProps = (state, ownProps) => {
  return {
      user:firebase.auth().currentUser,
  }
}


export default compose(
  connect(mapStateToProps),
)(View);



