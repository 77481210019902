import React, {useState  } from 'react'
//import firebase from 'firebase';
import {Container,List,ListItemButton,ListItemText} from '@mui/material';

import { NavLink } from 'react-router-dom'
import axios from 'axios'

import Loader from "../../components/other/Loader"
import Header from "../../components/header/Header"
import { Box } from '@mui/system';

var timeoutTimer = null


function View() { 


  const [values, setValues] = useState();
  const [limit, setLimit] = useState(30);
  const [{loading}, setForm] = useState({loading:true});


  const handleTimeout = () => {
    if(loading)
      setForm({ error:"Timeout"});
  };
  
  const LoadMore = ()=>{
    LoadQuestions(limit+30)
    setLimit(limit+30)
  }

  const LoadQuestions = (LimitSize=null) =>{
    
    timeoutTimer = setTimeout(handleTimeout, 30000)

    axios.post('https://api.uniqule.com/question/list',{limit:LimitSize?LimitSize:limit}).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);
      
      if(Result.data && Result.data.questions)
      {
        setValues(Result.data.questions);
        setForm({});
      }
    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setForm({ error:"Server error"});
    });
  }




  if(loading)
  {
    LoadQuestions()
    return <main ><Header/><Loader/></main >
  }



  return(<main >
            <Header/>
            
            <Container >
                <Box><h1>Questions</h1></Box>


                <List  className="QuestionList">
                    {values && values.map((item)=>{
                        return (<ListItemButton component={NavLink} to={"/question/"+item.id} className="QuestionListItem">
                                  <ListItemText className="question" primary={item.question}  secondary={(item.countAnswered<1000? "<":"")+parseInt(item.countAnswered/1000 + 1)+"K"}/>
                                </ListItemButton>
                        )
                    })}



                      <ListItemButton onClick={LoadMore}  className="loadMoreButton" >
                        <ListItemText id="Load More" primary="Load More" />
                      </ListItemButton>
                </List >
            </Container>
        </main>
  );
}






export default (View);



