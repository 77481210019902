import React, { useState } from 'react';

import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


import {Button, Alert,Grid} from '@mui/material';
//import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import axios from 'axios';

import Header from "../components/header/Header"


var timeoutTimer = null
function Home() { 

  const [answer, setAnswer] = useState();
  const [{loading,error}, setForm] = useState({});
  const [{question,questionId,answerId}, setQuestion] = useState({});
  
  const [Contactvalues, setContactvalues] = useState({});
  const [{ContactLoading,ContactFinished,ContactError}, setContactForm] = useState({});

  const handleTimeout = () => {
    if(loading)
      setForm({ error:"Timeout"});
  };

  const LoadQuestion = () =>{
    setForm({loading:true})
    timeoutTimer = setTimeout(handleTimeout, 30000)
    const MessageData = {}

    axios.post('https://api.uniqule.com/question',MessageData).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);
      
      //console.log(Result)
      if(Result.data && Result.data.question)
      {
        setQuestion(Result.data.question);
        setAnswer()
        setForm({});
      }
      else
      {
        setForm({ error:"Server error"})
      }

    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setForm({ error:"Server error"});
    });

  }


  const submitQuestion = ()=>{
    

    console.log(answer)

    if(answer === "" || !answer)
    {
      alert("Answer cannot be blank")
      return
    }


    if(answer && answer.length > 280)
    {
      alert("Answer has to be less then 280 characters")
      return
    }

    setForm({loading:true})
    timeoutTimer = setTimeout(handleTimeout, 30000)

    const MessageData = { "answerId":answerId,
    "questionId":questionId,
    "answer":answer
    }

    console.log(MessageData)
    axios.post('https://api.uniqule.com/question',MessageData).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);
      
      console.log(Result)
      if(Result.data && Result.data.question)
      {
        setQuestion(Result.data.question);
        setAnswer()
        setForm({});
      }
      else
      {
        setForm({ error:"Server error"})
      }

    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setForm({ error:"Server error"});
    });
  }






  
  const handleChange = (event) => {
    setContactvalues({
        ...Contactvalues,
        [event.target.name]: event.target.value
    });
};


const handleSubmit = (event) => { 
    event.preventDefault();
    if(ContactLoading)
      return

    console.log("submit")
    timeoutTimer = setTimeout(handleTimeout, 30000)
    setContactForm({ContactLoading:true});

    if(firebase.auth().currentUser)
    {
        firebase.auth().currentUser.getIdToken().then(function(idToken) {

            handleForm(idToken);

        }).catch(function(error) {
                if(timeoutTimer)
                    clearInterval(timeoutTimer);
                    setContactForm({ContactError:true});
        });
    }
    else
    {
        handleForm(null);
    }
};

  const handleForm = (idToken) =>{
      
    var MessageData = {};

    //MessageData.service = "uniqule";


    if(Contactvalues.name)
        MessageData.name = Contactvalues.name;

    if(Contactvalues.email)
        MessageData.email = Contactvalues.email;
        
    if(Contactvalues.message)
        MessageData.message = Contactvalues.message;


        axios.post('https://api.uniqule.com/contact',MessageData).then(Result => {
          if(timeoutTimer)
              clearInterval(timeoutTimer);
          
          console.log(Result)
          if(Result.data && Result.data.success)
          {
            setContactForm({ContactFinished:true});
          }
          else
          {
            setContactForm({ ContactError:"Server error"})
          }
    
        }).catch(err => {
          //setError(err);
          if(timeoutTimer)
              clearInterval(timeoutTimer);
              setContactForm({ ContactError:"Server error"});
        });

  }


  if(!loading && !error && !question)
  {
    LoadQuestion();
  }


  
  return (<main>
    <Box className="fullView">
            <Box className="InsizeFullView">
              {loading?
                <Box className="QuestionLoading">Loading...</Box>
              : error?
                <Box className="QuestionLoading">An error has happen.</Box>
              : question ?
                <Box  className="QuestionBox">
                <Box className="QuestionText">{question}</Box>

                  <Box>
                    <TextField
                    className="QuestionTextField"
                      multiline
                      //maxRows={4}
                      placeholder='Your Answer'
                      value={answer}
                      onChange={(event)=>setAnswer(event.target.value)}
                    />
                  </Box>
                  <Button className="QuestionSubmit" onClick={submitQuestion}>Submit</Button>
                </Box>
              :
                <Box className="QuestionLoading">An error has happen.</Box>
            }

              <Box className="HomeMenu">
                <Header />
              </Box>
            </Box>
            </Box>

          <Box>
            <Container className="center" >
                
              <Box className="section">
                <h1>unquile</h1>
                <p>We are people like everyone else.</p>
                <p>We want to know more about different people from around the world.</p>
                <p>We use different algorithms to generate answers from all the answers for a questions.</p>
              </Box>
            
              <Box className="section">
                <h2>Our questions to you</h2>
                <p>Every answer is used to generate these answers.</p>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    
                    <h3>Don't like the answers?</h3>
                    <p>Answer more questions and they will change.</p>

                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h3>See your answer?</h3>
                    <p>There must be a lot of people like you.</p>
                  </Grid>
                </Grid>

                <Box>
                  {/* questions */}
                </Box>
                <Button component={NavLink} to="/questions">See more</Button>
              </Box>


              <Box className="section">
                <h2>Question Category</h2>
                <p style={{textAlign:"center"}}>Category is coming soon. </p>
              </Box>



              <Box className="section">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <h2>Add your own question</h2>
                <p>If you have a question to add, ask it.</p>
                <Button component={NavLink} to="/profile/questions">Ask a Question</Button>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <h2>See your questions</h2>
                <p>See how many have answered your questions.</p>
                <Button component={NavLink} to="/profile">View</Button>
                
              </Grid>
            </Grid>
            </Box>







            
            <Box className="section">
            <h2>Ask your friends or community</h2>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <h3>Make your own</h3>
                <p>Make your own QR and share it around.</p>
                
                <Button component={NavLink} to="/profile/tags">Make QR</Button>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <h3>Buy stickers, cards and other</h3>
                <p>Help support us.</p>
                <Button component={NavLink} to="/shop">Shop</Button>
              </Grid>
            </Grid>
            </Box>



            
            <Box className="section">
            <h2>Privacy</h2>
            <p>We want all you to feel safe that any answers you submit is considered in our algorithm but is protected.</p>
            <p>We want you to feel save that you can answer any question in your own way without getting judged.</p>
            <p>Please read our Privacy Policy to understand more.</p>
            <Button component={NavLink} to="/privacy">Privacy Policy</Button>

            </Box>





            <Box className="section">
              <h2>Contact us</h2>
              <p>Feel free to contact us.</p>

                  {ContactFinished ?
                    <h3>Thank you</h3>
                  :
                    <form onSubmit={handleSubmit} className="{classes.ContactForm}" noValidate autoComplete="off">
                      <Box >
                        {ContactError ? <Alert severity="error">Error — There was an error.</Alert> :null}
                        {ContactLoading ? <Alert severity="info">Loading  —  please wait.</Alert> :null}
                        {ContactFinished ? <Alert severity="success">Saved</Alert> :null}
                      </Box>

                      <TextField fullWidth onChange={handleChange} name="name" className="ContactTextField" id="standard-basic" variant="outlined" label="Name" />
                      <TextField fullWidth onChange={handleChange} name="email" className="ContactTextField" id="standard-basic" variant="outlined" label="Email" />
                      <TextField fullWidth onChange={handleChange} name="message" className="ContactTextField" id="standard-basic" variant="outlined" label="Message" multiline rows={4}/>

                      <Button  type="submit" className="{classes.SendButton}" variant="contained">Send</Button>
                    </form>
                  }
            </Box>


            </Container>
          </Box>

          </main> );
}







const mapStateToProps = (state, ownProps) => {
  return {
      user:firebase.auth().currentUser,
  }
}




export default compose(
  connect(mapStateToProps),
)(Home);



