import React, {useState  } from 'react'
//import firebase from 'firebase';
import {Container,TextField,DialogActions,DialogContentText,DialogContent,DialogTitle,Dialog,List,ListItemButton,Button,ListItemText} from '@mui/material';
import Alert from '@mui/material/Alert';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { compose } from 'redux'
import { isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

//import {SubmitContact} from '../store/actions/ContactAction'
import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'
import axios from 'axios'
import Login from "../Auth/Login"

import Sidebar from "../../components/profile/Sidebar"
import Loader from "../../components/other/Loader"
import Header from "../../components/header/Header"
import { Box } from '@mui/system';

var timeoutTimer = null


function View({user}) { 

  const [Question, setQuestion] = useState();
  const [values, setValues] = useState();
  const [{loading,error}, setNewQuestion] = useState({});
  const [limit, setLimit] = useState(30);
  const [{loadingQuestions}, setForm] = useState({loadingQuestions:true});
  const [openAddDialog, setAddDialog] = useState(false);

  const auth = useSelector(state => state.firebase.auth)


  
  const handleOpenAddDialog= () => {
    setAddDialog(true);
    console.log("open")
  };

  const handleCloseAddDialog = () => {
    setAddDialog(false);
  };


  const handleTimeout = () => {
    if(loadingQuestions)
      setForm({ error:"Timeout"});
  };
  
  const LoadMore = ()=>{
    LoadQuestions(limit+30)
    setLimit(limit+30)
  }

  const LoadQuestions = (LimitSize=null) =>{
    
    timeoutTimer = setTimeout(handleTimeout, 30000)
    firebase.auth().currentUser.getIdToken().then(function(idToken) {

    axios.post('https://api.uniqule.com/profile/questions',{limit:LimitSize?LimitSize:limit},{"headers":{"Authorization": idToken}}).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);

      console.log(Result.data)

      if(Result.data && Result.data.questions)
      {
        setValues(Result.data.questions);
        setForm({});
      }
    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setForm({ error:"Server error"});
    });
  })
  }


  
  const SubmitQuestion = () =>{
    
    setNewQuestion({loading:true})
    timeoutTimer = setTimeout(handleTimeout, 30000)


    if(!Question || Question === "")
    {
      setNewQuestion({error:"Question can not be empty."})
      return
    }

    if( Question>200)
    {
      setNewQuestion({error:"Question is too long, must be less then 200 characters long."})
      return
    }



    firebase.auth().currentUser.getIdToken().then(function(idToken) {

    axios.post('https://api.uniqule.com/question/add',{question:Question},{"headers":{"Authorization": idToken}}).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);

      console.log(Result.data)

      if(Result.data && Result.data.id)
      {
        setNewQuestion({})
        setQuestion()
        setAddDialog(false);
        LoadQuestions()
      }
      else
      {
        setNewQuestion({error:"Server error"})
      }
    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setNewQuestion({error:"Cannot submit"})
    });
  })
  }






  if(!isLoaded(auth))
  {
    return <main ><Header/><Loader/></main >
  }
  
  if(!user)
  {
    return <Login/>
  }


  if(loadingQuestions )
  {
    LoadQuestions()
    return <main ><Header/><Container><Sidebar><Loader/></Sidebar></Container ></main >
  }



  return(<main >
          <Header/>
            <Container >
              <Sidebar>

              <Box className="ProfileHeader">
                <Box className="ProfileHeaderContent">
                  <Button onClick={handleOpenAddDialog}>Add</Button>
                </Box>
              </Box>


                <List  className="QuestionList">
                    {values && values.length === 0 ? <ListItemText className="center" primary="You have No Question, please add one."/>: null}


                    {values && values.map((item)=>{
                        return (<ListItemButton component={NavLink} to={"/question/"+item.id} className="QuestionListItem">
                                  <ListItemText className="question" primary={item.question}  secondary={<span>{item.approved ? <span className="approved">approved</span> :  <span className="reviewed">Being reviewed</span>} - Answered: {item.countAnswered ? (item.countAnswered<1000? "<":"")+parseInt(item.countAnswered/1000 + 1)+"K" : "<1K"}</span>}/>
                                </ListItemButton>
                        )
                    })}


{values && values.length > 0 ? <ListItemButton onClick={LoadMore}  className="loadMoreButton" >
                        <ListItemText id="Load More" primary="Load More" />
                      </ListItemButton>:null}
                </List >


              </Sidebar>
            </Container>






            <Dialog
              open={openAddDialog}
              //onClose={handleCloseAddDialog}
              //PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              maxWidth="md"
              fullWidth
      
            >
              <DialogTitle>
                Add Question
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  <Box >
                    {error ? <Alert severity="error">Error — {error}</Alert> :null}
                    {loading ? <Alert severity="info">Loading  —  please wait.</Alert> :null}
                  </Box>

                  <TextField margin="dense" value={Question} onChange={(event)=>setQuestion(event.target.value)} multiline fullWidth label="Question" variant="outlined" />
                  <Box>
                  Question must be less than 200 characters long. All Questions will be reviewed.
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleCloseAddDialog}>
                  Cancel
                </Button>
                <Button onClick={SubmitQuestion}>Submit</Button>
              </DialogActions>
            </Dialog>

            
          </main>
  );
}




const mapStateToProps = (state, ownProps) => {
  return {
      user:firebase.auth().currentUser,
  }
}


export default compose(
  connect(mapStateToProps),
)(View);



