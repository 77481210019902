import React, {Component } from 'react'

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


const Loader = (props) => {

    return( <Box className="LoaderView">
                    <Box className="LoadingText">Loading...</Box>
            </Box>)

}

export default (Loader);