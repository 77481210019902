import React, {useState  } from 'react'
import {Container} from '@mui/material';
import {useParams,  } from 'react-router-dom'
import axios from 'axios'

import Loader from "../../components/other/Loader"
import Header from "../../components/header/Header"
import { Box } from '@mui/system';

var timeoutTimer = null


function View() { 
  const [QuestionDetails, setQuestionDetails] = useState({id:"test",name:"test",cost:"",imagee:"test",description:"test"});
  const [{loading}, setForm] = useState({loading:true});
  let { id } = useParams();


  const handleTimeout = () => {
    if(loading)
      setForm({ error:"Timeout"});
  };

  const LoadQuestions = (LimitSize=null) =>{
    
    timeoutTimer = setTimeout(handleTimeout, 30000)

    axios.post('https://api.uniqule.com/question/details',{id:id}).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);
      
      if(Result.data )
      {
        setQuestionDetails(Result.data);
        setForm({});
      }
    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setForm({ error:"Server error"});
    });
  }



  if(loading)
  {
    LoadQuestions()
    return <main ><Header/><Loader/></main >
  }

  







  

  
        return (<main className="root">
                    <Header/>

                    <Container sx={{marginTop:"50px"}}>
                        <h2 className="Name">{QuestionDetails.question ? QuestionDetails.question:null}</h2>
                        {QuestionDetails.countAnswered ? <p className="countAnswered">This question was answered {(QuestionDetails.countAnswered<1000? "<":"")+parseInt(QuestionDetails.countAnswered/1000 + 1)+"K"} times.</p> :null}
            
                        <Box className="section" sx={{marginTop:"100px"}}> 
                            <h2 className="Name">Generated Answers</h2>
                            <p>This question requires more answers before answers can be generated.</p>
                        </Box>
                        
                    </Container>

                </main>);

}






export default (View);



