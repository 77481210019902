import React, {Component,useState  } from 'react'
import { Link, Redirect } from 'react-router-dom'
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import {Container,List,ListItemButton,Button,ListItemText} from '@mui/material';
import Alert from '@mui/material/Alert';

//import {SubmitContact} from '../store/actions/ContactAction'
import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'
import axios from 'axios'

import Loader from "../../components/other/Loader"
import Header from "../../components/header/Header"
import { Box } from '@mui/system';


var timeoutTimer = null




function View() { 

    const [values, setValues] = useState();
    const [limit, setLimit] = useState(30);
    const [{loading}, setForm] = useState({loading:true});
  
  
    const handleTimeout = () => {
      if(loading)
        setForm({ error:"Timeout"});
    };
    
    const LoadMore = ()=>{
      LoadQuestions(limit+30)
      setLimit(limit+30)
    }
  
    const LoadQuestions = (LimitSize=null) =>{
      
      timeoutTimer = setTimeout(handleTimeout, 30000)
  
      axios.post('https://api.uniqule.com/products',{limit:LimitSize?LimitSize:limit}).then(Result => {
        if(timeoutTimer)
            clearInterval(timeoutTimer);
        
        if(Result.data && Result.data.products)
        {
          setValues(Result.data.products);
          setForm({});
        }
      }).catch(err => {
        //setError(err);
        if(timeoutTimer)
            clearInterval(timeoutTimer);
            setForm({ error:"Server error"});
      });
    }
  
  
  
  
    if(loading)
    {
      LoadQuestions()
      return <main ><Header/><Loader/></main >
    }

    







  return(<main >
            <Header/>
            
      <Container>
                <h1>Support us</h1>

                <Box>
                    {/*values && values.map((item)=>{

                        return (<Box Component={NavLink} to={"/shop/"+item.id}>
                                    {item.image ? <img src={item.image} alt={item.name}/>:<Box>No Images</Box>}
                                    <Box>{item.name}</Box>
                                    <Box>{item.cost}</Box>
                                </Box>)
                    })*/}
                </Box>


                <List  className="shopList">
                    {values && values.map((item)=>{
                        return (<ListItemButton component={NavLink} to={"/shop/"+item.id} className="ShopListItem">
                                    <Box className="ShopListItemImageBox" >
                                        {item.image ? <img src={item.image} alt={item.name} /> : <Box className="ShopListItemNoImage" >No Image</Box> }
                                    </Box>
                                    <ListItemText className="" primary={item.name}  secondary={item.stock && item.stock > 0 ? "$" + item.cost + " — " + item.stock + " available" : "Sold out"}/>
                                </ListItemButton>
                        )
                    })}



                      <ListItemButton onClick={LoadMore}  className="loadMoreButton" >
                        <ListItemText id="Load More" primary="Load More" />
                      </ListItemButton>
                </List >
            </Container>
        </main>
  );
}






export default (View);



