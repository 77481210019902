

// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAG8b6TMgqY9J464euQtzDOwBESaXtdpWQ",
  authDomain: "green-mystery-338507.firebaseapp.com",
  projectId: "green-mystery-338507",
  //storageBucket: "green-mystery-338507.appspot.com",
  messagingSenderId: "311724700344",
  appId: "1:311724700344:web:c824a64119b571db147a4d",
  measurementId: "G-7KQE0F100H"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth;

export default firebase;