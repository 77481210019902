import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { compose } from 'redux'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { connect } from 'react-redux'
//import { firestoreConnect } from  'react-redux-firebase'

import Home from './views/Home';
import Terms from './views/Terms';
import Privacy from './views/Privacy';
//import About from './views/About';
//import Contact from './views/Contact';

import Login from './views/Auth/Login';
import Logout from './views/Auth/Logout';

import ProfileQuestions from './views/Profile/Questions';
import ProfileTags from './views/Profile/Tags';
import ProfileDetails from './views/Profile/Details';


import QuestionsList from './views/Questions/List';
import QuestionsDetails from './views/Questions/Details';


import Shop from './views/Shop/Shop';
import ShopDetails from './views/Shop/Details';
import ShopMake from './views/Shop/Make';




function App() {

  return (
      <BrowserRouter>
          <Routes >
            <Route exact path='/' element={<Home/>}  />

            <Route exact path='/terms' element={<Terms/>} />
            <Route exact path='/privacy' element={<Privacy/>} />
            
            {/*<Route exact path='/about' element={<About/>} />*/}
            {/*<Route exact path='/contact' element={<Contact/>} />*/}

            <Route exact path='/login' element={<Login/>} />
            <Route exact path='/logout' element={<Logout/>} />

            <Route exact path='/profile' element={<ProfileQuestions/>} />
            <Route exact path='/profile/questions' element={<ProfileQuestions/>} />
            <Route exact path='/profile/tags' element={<ProfileTags/>} />
            <Route exact path='/profile/details' element={<ProfileDetails/>} />

            <Route exact path='/questions' element={<QuestionsList/>} />
            <Route exact path='/questions/:cat' element={<QuestionsList/>} />
            <Route exact path='/question/:id' element={<QuestionsDetails/>} />

            <Route exact path='/shop' element={<Shop/>} />
            <Route exact path='/shop/:id' element={<ShopDetails/>} />
            <Route exact path='/make' element={<ShopMake/>} />

            <Route exact path='/id/:id' element={<Home/>} />
            
            <Route  path='*' element={<Home/>}  status={404}/>

          </Routes >
      </BrowserRouter>
  );
}




const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser,
      }
}


export default compose(
  connect(mapStateToProps),
) (App);

