import React,{connect} from 'react'
import { Link, Navigate } from 'react-router-dom'
//import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
//import {Userlogout} from '../../store/actions/authActions'


const Logout = (props) => {
    //props.Userlogout();
    const firebase = getFirebase()
    firebase.auth().signOut()
    return <Navigate to="/" />
}
/*
const mapDispatchToProps = (dispatch) => {
    return {
        Userlogout: () => dispatch(Userlogout())
    }
  }*/

export default /*connect(mapDispatchToProps)*/(Logout)