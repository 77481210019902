import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import Header from '../../components/header/Header';

const Login = () => {
    const classes = {}//useStyles();
    const firebase = getFirebase()
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: window.location.pathname,
        // We will display Google and Facebook as auth providers.
        //provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,      
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ], 
        
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                var isNewUser = authResult.additionalUserInfo.isNewUser;
                return false;
            },
            uiShown: function() {
            }
        }
    };

    return (<main>
        <Header/>
        <Container className={classes.MainContainer}>

            <h1 className={classes.title}>Login</h1>

            <Box id="firebaseui-auth-container" className={classes.firebaseui}>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
            </Box>
        </Container>
        </main>
    )
    
}
export default Login
