import React,{useState} from 'react'
import { NavLink } from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';




const Sidebar = ({children}) => {
    
        const [DisplayMenu, SetMenu] = useState(false);

        
    const handleMenu = ()=>{
        SetMenu(!DisplayMenu)
    }

    const handleMenuClose = ()=>{
        SetMenu(false)
    }






    const Menu=(<List>
        <ListItemButton onClick={handleMenuClose} className="ProfileListItem" component={NavLink} to="/profile">
            <ListItemText primary="Questions" />
        </ListItemButton>
        <ListItemButton onClick={handleMenuClose} className="ProfileListItem" component={NavLink} to="/profile/tags">
            <ListItemText primary="QR & Tags" />
        </ListItemButton>
        <ListItemButton onClick={handleMenuClose} className="ProfileListItem" component={NavLink} to="/profile/details">
            <ListItemText primary="Details" />
        </ListItemButton>
        
        <ListItem className="ProfileListItem">
            <Button color="error" fullWidth variant="outlined" onClick={handleMenuClose} className="ProfileListButton" component={NavLink} to="/logout">
                Logout 
            </Button>
        </ListItem>
    </List>)





    return (<Box className="ProfileBox">

                <Box className="ProfileSideBar"> 
                
                    <Box className="ProfileMobileMenu"> 
                        <IconButton onClick={handleMenu} aria-label="upload picture" component="span">
                            <MenuIcon />
                        </IconButton>
                    
                        <Collapse in={DisplayMenu}>
                            {Menu}
                        </Collapse>
                    </Box> 

                    <Box className="ProfileDesktopMenu">
                        {Menu}
                    </Box>
                </Box>   
                <Box className="ProfileVeiw">
                    {children}
                </Box>
            </Box>);
}


  export default (Sidebar);
  