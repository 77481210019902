import React, {Component,useState  } from 'react'
import { Link, Redirect } from 'react-router-dom'
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import {Container,FormControl,TextField,Button,Paper} from '@mui/material';
import Alert from '@mui/material/Alert';

//import {SubmitContact} from '../store/actions/ContactAction'
import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'

import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import { withFirestore, isLoaded, isEmpty } from 'react-redux-firebase'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


import axios from 'axios'

import Loader from "../../components/other/Loader"
import Header from "../../components/header/Header"
import Login from "../Auth/Login"
import { Box } from '@mui/system';

function View({user}) { 
  const [values, setValues] = useState({});


  if(!user)
    return(<Login/>)


  return(<main >
            <Header/>
            <Box>

            </Box>
        </main>
  );
}








const mapStateToProps = (state, ownProps) => {
    return {
        user:firebase.auth().currentUser,
    }
  }
  
  
  
  export default compose(
    connect(mapStateToProps),
  )(View);
  