import React, {useState  } from 'react'
//import firebase from 'firebase';
import {Container,Button} from '@mui/material';
import {useParams } from 'react-router-dom'

//import {SubmitContact} from '../store/actions/ContactAction'
import parse from 'html-react-parser';

import { NavLink } from 'react-router-dom'
import axios from 'axios'

import Loader from "../../components/other/Loader"
import Header from "../../components/header/Header"
import { Box } from '@mui/system';

var timeoutTimer = null


function View() { 
  const [ShopDetails, setShopDetails] = useState({id:"test",name:"test",cost:"",imagee:"test",description:"test"});
  const [{loading}, setForm] = useState({loading:true});
  let { id } = useParams();


  const handleTimeout = () => {
    if(loading)
      setForm({ error:"Timeout"});
  };

  const LoadQuestions = (LimitSize=null) =>{
    
    timeoutTimer = setTimeout(handleTimeout, 30000)

    axios.post('https://api.uniqule.com/products/details',{id:id}).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);
      
      if(Result.data )
      {
        setShopDetails(Result.data);
        setForm({});
      }
    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setForm({ error:"Server error"});
    });
  }



  if(loading)
  {
    LoadQuestions()
    return <main ><Header/><Loader/></main >
  }

  







  

  
        return (<main className="root">
                    <Header/>

                    <Container sx={{marginTop:"50px"}}>
                        <Box className="MainText" display={{xs:"block",sm:"block",md:"none"}}>
                            <Box className="TitleBlock">
                                <h2 className="Name">{ShopDetails.name ? ShopDetails.name:null}</h2>
          
                                {ShopDetails.category ?<Box>{ShopDetails.category}</Box>:null}
              
                                { !ShopDetails.stock || ShopDetails.stock <= 0 ?
                                    <Box  sx={{marginBottom:"10px",marginTop:"10px"}}>
                                        Out of stock
                                    </Box>
                                : 
                                    <Box  sx={{marginBottom:"10px",marginTop:"10px"}}>
                                        <Box>
                                            <Button component={NavLink}  to={"/device/" + ShopDetails.id} variant="outlined">Buy</Button>
                                        </Box>
                                        <Box className="Price" >
                                            {ShopDetails.cost > 0 ? "$"+ShopDetails.cost:"FREE"}
                                        </Box>
                                    </Box>
                                }
                                

                            </Box>
                        </Box>





                        <Box className="GridLayout">
                            
                                <Box className="ImageGalleryBox">
                                    {ShopDetails.image ? <img  className="Image" src={ShopDetails.image} alt={ShopDetails.name+" Image"}/> : <Box className="ShopItemNoImage" >No Image</Box> }
                                </Box>

                            <Box className="MainText" display={{xs:"none",sm:"none",md:"block"}}>
                                <Box className="TitleBlock">
                                    <h2 className="Name">{ShopDetails.name ? ShopDetails.name:null}</h2>
                
                                    {ShopDetails.category ?<Box> {ShopDetails.category}</Box>:null}
                    

                                    { !ShopDetails.stock || ShopDetails.stock <= 0 ?
                                        <Box  sx={{marginBottom:"10px",marginTop:"10px"}}>
                                            Out of stock
                                        </Box>
                                    : 
                                        <Box  sx={{marginBottom:"10px",marginTop:"10px"}}>
                                            <Box>
                                                <Button component={NavLink}  to={"/device/" + ShopDetails.id} variant="outlined">Buy</Button>
                                            </Box>
                                            <Box className="Price" >
                                                {ShopDetails.cost > 0 ? "$"+ShopDetails.cost:"FREE"}
                                            </Box>
                                        </Box>
                                    }

                                    
                                </Box>


                            <Box className="body">
                                {ShopDetails.description ? parse(ShopDetails.description):null}
                            </Box>
                        </Box>
                    </Box>
                    
                    <Box className="body"  display={{xs:"block",sm:"block",md:"none"}}>
                        {ShopDetails.description ? parse(ShopDetails.description):null}
                    </Box>

                </Container>
            </main>);

}






export default (View);



