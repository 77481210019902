import React, {useState  } from 'react'
//import firebase from 'firebase';
import {Select,FormControl,Container,MenuItem,TextField,Grid,Button} from '@mui/material';
import Alert from '@mui/material/Alert';

//import {SubmitContact} from '../store/actions/ContactAction'
import { connect } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'

import axios from 'axios'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { compose } from 'redux'
import { useSelector } from 'react-redux'

import Login from "../Auth/Login"

import Sidebar from "../../components/profile/Sidebar"
import Loader from "../../components/other/Loader"
import Header from "../../components/header/Header"
import { Box } from '@mui/system';

var timeoutTimer = null


function View({user,firestore,Profile}) { 
  const classes = {};
  const [values, setValues] = useState();
  const [{loading,error,saved,finished}, setForm] = useState({});
  const [loadingData, setLoadingData] = useState(true);

  const auth = useSelector(state => state.firebase.auth)


  if(!isLoaded(auth))
  {
    return <main ><Header/><Loader/></main >
  }

  const handleTimeout = () => {
    console.log()
    if(!finished)
      setForm({error:true});
};

  if(loadingData && !values && auth)
  {
   
    timeoutTimer = setTimeout(handleTimeout, 30000)

    firebase.auth().currentUser.getIdToken().then(function(idToken) {

    axios.post('https://api.uniqule.com/profile',{},{"headers":{"Authorization": idToken}}).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);
      
          //console.log(Result)
      if(Result.data)
      {
        setValues({...Result.data});
        setLoadingData(false);
        setForm({});
      }
    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setForm({ error:"Server error"});
      });
    });
  }






  const handleChange = (event) => {
    setForm({});
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleNotificationsChange = (event) => {
    setForm({});
    setValues({
      ...values,
      notifications:{
        ...values.notifications,
        [event.target.name]: event.target.value
      }
    });
  };

  const handleSubmit = (event) => { 
    event.preventDefault();
    
    
    timeoutTimer = setTimeout(handleTimeout, 30000)

    if(values && values.email === '')
    {
      alert("Email cannot be blank.")
      return
    }


    if(values && values.name === '')
    {
      alert("Name cannot be blank.")
      return
    }



    setForm({loading:true});
    //Add checks
    firebase.auth().currentUser.getIdToken().then(function(idToken) {

    axios.post('https://api.uniqule.com/profile/update',values,{"headers":{"Authorization": idToken}}).then(Result => {
      if(timeoutTimer)
          clearInterval(timeoutTimer);
      
          console.log(Result.data)
        setForm({saved:true});


    }).catch(err => {
      //setError(err);
      if(timeoutTimer)
          clearInterval(timeoutTimer);
          setForm({ error:"Server error"});
    });
  })


  };



  if(!isLoaded(auth))
  {
    return <main ><Header/><Loader/></main >
  }

  
  if(!user)
  {
    return <Login/>
  }


  if(loadingData )
  {
    return <main ><Header/><Container><Sidebar><Loader/></Sidebar></Container ></main >
  }


  return(<main >
          <Header/>
            <Container >
              <Sidebar>

              

              <Box className="ProfileDetails">
      <form onSubmit={handleSubmit} action="post"
        autoComplete="off"
        noValidate
      >
            <h2>Details</h2>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  onChange={handleChange}
                  
                  value={values.country}
                  variant="outlined"
                />
              </Grid>
              

              <Grid
                item
                md={12}
                xs={12}
              >

              
<Box >
                      {error ? <Alert severity="error">Error — There was an error.</Alert> :null}
                      {loading ? <Alert severity="info">Loading  —  please wait.</Alert> :null}
                      {saved ? <Alert severity="success">Saved</Alert> :null}
                    </Box>
                    <br/>
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              Save details
            </Button>
            </Grid>
            </Grid>
    <h2>Notifications</h2>
     <p>Manage the notifications</p>
     
   
     <Grid
       container
       spacing={6}
       wrap="wrap"
     >


         

<Grid item md={3} sm={6}  xs={12} className={classes.NotificationsLabel}>
  <FormControl fullWidth className={classes.formControl}>
  Questions Notifications: 
  </FormControl>
</Grid>
<Grid item md={9} sm={6} xs={12} >
  <FormControl fullWidth className={classes.formControl}>
    <Select value={values.notifications && "Questions" in values.notifications ?  values.notifications.Questions : 1} onChange={handleNotificationsChange} name="Questions" className={classes.selectEmpty} inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={1}>All</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid> 


<Grid item md={3} sm={6}  xs={12} className={classes.NotificationsLabel}>
  <FormControl fullWidth className={classes.formControl}>
  Generated Answers Notifications: 
  </FormControl>
</Grid>
<Grid item md={9} sm={6} xs={12} >
  <FormControl fullWidth className={classes.formControl}>
    <Select value={values.notifications && "GeneratedAnswers" in values.notifications ?  values.notifications.GeneratedAnswers : 1} onChange={handleNotificationsChange} name="GeneratedAnswers" className={classes.selectEmpty} inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={1}>All</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>




<Grid item md={3} sm={6}  xs={12} className={classes.NotificationsLabel}>
  <FormControl fullWidth className={classes.formControl}>
  uniqule Notifications: 
  </FormControl>
</Grid>
<Grid item md={9} sm={6} xs={12} >
  <FormControl fullWidth className={classes.formControl}>
    <Select value={values.notifications && "uniqule" in values.notifications ?  values.notifications.uniqule : 1} onChange={handleNotificationsChange} name="uniqule" className={classes.selectEmpty} inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={1}>All</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>


    












       <Grid
                item
                md={12}
                xs={12}
              >

<Box>
                      {error ? <Alert severity="error">Error — There was an error.</Alert> :null}
                      {loading ? <Alert severity="info">Loading  —  please wait.</Alert> :null}
                      {saved ? <Alert severity="success">Saved</Alert> :null}
                    </Box>
              <br/>
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Save details
            </Button>
            </Grid>
     </Grid>

    </form>
      </Box>



              </Sidebar>
            </Container>
          </main>
  );
}




const mapStateToProps = (state, ownProps) => {
  return {
      user:firebase.auth().currentUser,
  }
}


export default compose(
  connect(mapStateToProps),
)(View);

